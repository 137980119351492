<template>
  <article>
    <header> <h1>Dates</h1> </header>
    <p>
      Dans la page de titre des dossiers, la « date de l’original » est
      la date de rédaction ou compilation originelle de l’acte/document
      présenté dans le dossier, même si l’état retenu et reproduit
      remonte à une autre date – par exemple la copie d’un diplôme de
      849 dans un cartulaire du <span class="sc">xii</span><sup>e</sup> siècle sera classée et indexée à 849, quand bien
      même une partie du commentaire serait consacrée au cartulaire.
    </p>
    <p>
      Les listes de dossiers (liste générale, listes particulières
      obtenues après tri) sont classées par date, plus exactement par
      année, autant que possible ramenée au style du 1<sup>er</sup>
      janvier. Les actes/documents de datation incertaine sont classés à
      la date la plus récente possible.
    </p>
  </article>
</template>

<script>
export default {
    name: 'DocumentationDates'
}
</script>