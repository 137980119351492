module.exports = {
  'bold'        : require('../../assets/images/pic_typo01.svg'),
  'italic'      : require('../../assets/images/pic_typo02.svg'),
  'smallcaps'   : require('../../assets/images/pic_typo03.svg'),
  'superscript' : require('../../assets/images/pic_typo04.svg'),
  'underline'   : require('../../assets/images/pic_typo05.svg'),
  'del'         : require('../../assets/images/pic_typo06.svg'),
  'paragraph'   : require('../../assets/images/pic_edit01.svg'),
  'verse'       : require('../../assets/images/pic_edit02.svg'),
  'note'        : require('../../assets/images/pic_edit03.svg'),
  'segment'     : require('../../assets/images/pic_edit04.svg'),
  'page'        : require('../../assets/images/pic_edit05.svg'),
  'expan'       : require('../../assets/images/pic_semant02.svg'),
  'person'      : require('../../assets/images/pic_semant03.svg'),
  'location'    : require('../../assets/images/pic_semant04.svg'),
  'link'        : require('../../assets/images/pic_semant05.svg'),
  'cite'        : require('../../assets/images/pic_semant06.svg'),
};
