<template>
  <article>
    <header><h1>Personnes identifiées</h1></header>
    <p>
      Pour l'identification des noms de personnes, on suit dans les
      dossiers rédigés en français les usages des éditeurs français
      d'actes. Sauf forme consacrée par l'usage (Louis le Pieux, Saint
      Louis), on donne le plus possible la forme contemporaine traduite,
      tout en tenant compte si possible des formes régionales (Gautier,
      Wautier, Walter), et des formes étrangères (Stephen, Stepan,
      Stefano ...).
    </p>
  </article>
</template>

<script>
export default {
    name: 'DocumentationPersons'
}
</script>