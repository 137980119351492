var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[(!!_vm.document)?_c('div',{staticClass:"container is-fluid"},[_c('document-title-bar',{attrs:{"document":_vm.document}}),_c('div',{staticClass:"m-t-md"},[_c('div',{staticClass:"section-tabs tabs"},[_c('ul',[_c('li',{class:_vm.$attrs.section === 'notice' ||
              _vm.$attrs.section === undefined ||
              !_vm.transcriptionView
                ? "is-active"
                : ''},[_c('router-link',{attrs:{"to":{
                name: 'document-view',
                params: { docId: _vm.$attrs.docId, section: 'notice' },
              }}},[_vm._v(" Notice ")])],1),(_vm.transcriptionView)?_c('li',{class:_vm.$attrs.section === 'transcription' || _vm.$attrs.section === 'translation'
                ? 'is-active'
                : ''},[_c('router-link',{attrs:{"to":{
                name: 'document-view',
                params: { docId: _vm.$attrs.docId, section: 'transcription' },
              }}},[(_vm.translationView)?_c('span',[_vm._v(" Transcription et traduction")]):_c('span',[_vm._v(" Transcription ")])])],1):_vm._e(),(
              _vm.isCommentariesValidated &&
              !!_vm.commentariesView &&
              _vm.commentariesView.length > 0
            )?_c('li',{class:_vm.$attrs.section === 'commentaries' ? "is-active" : ''},[_c('router-link',{attrs:{"to":{
                name: 'document-view',
                params: { docId: _vm.$attrs.docId, section: 'commentaries' },
              }}},[_vm._v(" Commentaires ")])],1):_vm._e(),(_vm.isSpeechPartsValidated && !!_vm.speechPartsView)?_c('li',{class:_vm.$attrs.section === 'speech-parts' ? "is-active" : ''},[_c('router-link',{attrs:{"to":{
                name: 'document-view',
                params: { docId: _vm.$attrs.docId, section: 'speech-parts' },
              }}},[_vm._v(" Parties du discours ")])],1):_vm._e()])]),(!!_vm.document)?_c('div',{class:_vm.$attrs.section + '-view'},[_c('div',{staticClass:"columns"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageVisibility),expression:"imageVisibility"}],staticClass:"column",class:("" + (_vm.imageVisibility && _vm.showContent ? 'is-half' : ''))},[(!_vm.showContent)?_c('div',{staticClass:"visibility-controls m-b-md"},[_c('div',{staticClass:"field is-grouped"},[_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleImageVisibility,"visible":_vm.imageVisibility}},[_vm._v(" image ")]),(_vm.$attrs.section === 'notice')?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleNoticeVisibility,"visible":_vm.noticeVisibility}},[_vm._v(" notice ")]):_vm._e(),(
                    (_vm.transcriptionView && _vm.$attrs.section === 'transcription') ||
                    _vm.$attrs.section === 'translation'
                  )?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleTranscriptionVisibility,"visible":_vm.transcriptionVisibility}},[_vm._v(" transcription ")]):_vm._e(),(
                    (_vm.translationView && _vm.$attrs.section === 'transcription') ||
                    _vm.$attrs.section === 'translation'
                  )?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleTranslationVisibility,"visible":_vm.translationVisibility}},[_vm._v(" traduction ")]):_vm._e(),(_vm.$attrs.section === 'commentaries')?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleCommentariesVisibility,"visible":_vm.commentariesVisibility}},[_vm._v(" commentaires ")]):_vm._e(),(_vm.$attrs.section === 'speech-parts')?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleSpeechPartsVisibility,"visible":_vm.speechpartsVisibility}},[_vm._v(" parties du discours ")]):_vm._e()],1)]):_vm._e(),(_vm.document.manifest_origin_url && !_vm.isLoading)?_c('mirador-viewer',{attrs:{"manifest-url":_vm.document.manifest_url,"manifest-origin-url":_vm.document.manifest_origin_url,"canvas-index":0,"document-id":_vm.document.id}}):_c('img',{staticClass:"iiif-viewer-placeholder",attrs:{"src":require('@/assets/images/document_placeholder.svg')}})],1),(_vm.showContent)?_c('div',{staticClass:"column"},[_c('div',{staticClass:"visibility-controls m-b-md"},[_c('div',{staticClass:"field is-grouped"},[_vm._m(0),_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleImageVisibility,"visible":_vm.imageVisibility}},[_vm._v(" image ")]),(_vm.$attrs.section === 'notice')?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleNoticeVisibility,"visible":_vm.noticeVisibility}},[_vm._v(" notice ")]):_vm._e(),(
                    (_vm.transcriptionView && _vm.$attrs.section === 'transcription') ||
                    _vm.$attrs.section === 'translation'
                  )?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleTranscriptionVisibility,"visible":_vm.transcriptionVisibility}},[_vm._v(" transcription ")]):_vm._e(),(
                    (_vm.translationView && _vm.$attrs.section === 'transcription') ||
                    _vm.$attrs.section === 'translation'
                  )?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleTranslationVisibility,"visible":_vm.translationVisibility}},[_vm._v(" traduction ")]):_vm._e(),(_vm.$attrs.section === 'commentaries')?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleCommentariesVisibility,"visible":_vm.commentariesVisibility}},[_vm._v(" commentaires ")]):_vm._e(),(_vm.$attrs.section === 'speech-parts')?_c('visibility-toggle',{staticClass:"control",attrs:{"action":_vm.toggleSpeechPartsVisibility,"visible":_vm.speechpartsVisibility}},[_vm._v(" parties du discours ")]):_vm._e()],1)]),_c('div',[(_vm.$attrs.section === 'notice' || !_vm.transcriptionView)?_c('document-notice',{attrs:{"document":_vm.document}}):_vm._e(),(
                  (_vm.$attrs.section === 'transcription' ||
                    _vm.$attrs.section === 'translation') &&
                  _vm.transcriptionView
                )?_c('div',{staticClass:"content"},[(_vm.translationAlignmentVisibility)?_c('document-transcription-alignment'):_c('div',[(_vm.transcriptionVisibility)?_c('document-transcription',{attrs:{"readonly":true}}):_c('document-translation',{attrs:{"readonly":true}})],1)],1):_vm._e(),(
                  _vm.isTranscriptionValidated &&
                  _vm.$attrs.section === 'commentaries' &&
                  _vm.commentariesView !== null
                )?_c('document-commentaries'):_vm._e(),(
                  _vm.isTranscriptionValidated &&
                  _vm.$attrs.section === 'speech-parts' &&
                  _vm.speechPartsView != null
                )?_c('document-speech-parts'):_vm._e()],1)]):_vm._e()])]):_vm._e()])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"control"},[_c('span',[_vm._v("AFFICHAGE")])])}]

export { render, staticRenderFns }