<template>
  <article>
    <header> <h1>Contact</h1></header>
    <p>
      Veuillez adresser remarques et observations à : <a href="mailto:adele@chartes.psl.eu">adele@chartes.psl.eu</a>
    </p>
  </article>
</template>

<script>
export default {
    name: 'DocumentationContact'
}
</script>