<template>
  <article>
    <header><h1>À propos</h1></header>
    <p>
      Développée par l’École nationale des chartes avec le soutien de
      Scripta et du DIM Ile-de-France sous le patronnage de la Commission internationale de diplomatique, l’application ADELE entend
      proposer à un public d’étudiants et de chercheurs un manuel de
      diplomatique progressivement construit autour du déchiffrement
      interactif, de l’annotation, si besoin de la traduction et enfin
      de l’analyse diplomatique d’actes et de documents de gestion.
      Appuyée sur un outil de mise au point collaborative des dossiers,
      en classe ou par des chercheurs associés, elle est dès l’origine
      conçue pour dilater le champ de vision de son noyau primitif
      (actes médiévaux français) dans l’espace dans le type et dans le
      temps.
    </p>
  </article>
</template>

<script>
export default {
    name: 'DocumentationAbout'
}
</script>