<template>
  <article>
    <header><h1>Crédits</h1></header>
    Projet soutenu par
    <a
      href="https://scripta.psl.eu/"
      target="_blank"
    >Scripta</a> et par
    le DIM STCN, placé sous le patronage de la
    <a
      href="https://cidipl.org/"
      target="_blank"
    >Commission internationale de diplomatique</a>.
    <ul>
      <li>Conception scientifique : Olivier Guyotjeannin</li>
      <li>
        Conception informatique : École des chartes, missions projets
        numériques
      </li>
      <li>
        Saisies et conversions, tests : Pierre Pocart, Alexandre Gaudin,
        Sarah Rigaudeau
      </li>
      <li>Conseils : Jean-Baptiste Camps, Olivier Canteaut</li>
      <li>
        Collaborateurs extérieurs : 
        Les rangs des collaborateurs extérieurs sont appelés à croître rapidement. Aux pionnières, Cristina Mantegna (Rome), Pilar Ostos (Séville), Cristina Cunha (Porto) doivent se joindre de nombreux collègues, pour certains en lien avec le Séminaire de dplomatique comparée de l’École des chartes.
      </li>
    </ul>
  </article>
</template>


<script>
export default {
    name: 'DocumentationCredits'
}
</script>