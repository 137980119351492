<template>
  <article>
    <header><h1>Lieu de conservation : institution, cote</h1></header>
    <p>
      Les fac-similés de l’École utilisés dans Theleme (et donc dans la
      première partie d’ADELE) n’indiquent que très irrégulièrement la
      provenance des documents, et quasi jamais leur cote précise.
      L’interrogation de cette zone est donc à réserver essentiellement
      à des fins de gestion. – Le nom officiel développé de
      l’institution est complété, entre parenthèses, du nom du pays s’il
      n’est pas intégré au titre et, pour les institutions locales, du
      nom de la ville, aux mêmes conditions. Ces indications
      complémentaires sont données dans la forme française usuelle.
    </p>
    <p>
      Ex. Bibliothèque nationale de France, Archives nationales
      (France), British Library, Archives générales du Royaume
      (Belgique), Archivio di Stato Arezzo (Italie), Archives
      départementales Cher (France), Archivo de la Corona de Aragon
      (Espagne), etc.
    </p>
    <p>
      Le cas échéant, est donnée la cote (dans l’une des deux séries,
      dites Ancien fonds, AF, ou Nouveau fonds, NF) du fac-similé de
      l’École des chartes qui fait l’objet du dossier. La plupart de ces
      fac-similés ont été réalisés par l’École, d’abord en lithographie
      (cotes AF 1-632), puis selon divers procédés photographiques, dont
      l’héliogravure (cotes NF 1-, mais aussi AF 633-). Il convient
      d’être prévenu contre le mauvais rendu de certaines lithographies,
      dessinées par des artisans ignorant tout de la paléographie, quand
      d’autres sont de véritables chefs-d’œuvre.
    </p>
    <p>
      Les clichés numérisés, reproduits avec la libérale autorisation
      des institutions chargées de leur conservation, sont tantôt le
      fruit de commandes spéciales, tantôt le fait de prospections sur
      des sites internet.
    </p>
  </article>
</template>

<script>
export default {
    name: 'DocumentationConservation'
}
</script>